import { LOGIN_USER, LOGOUT_USER, SIGNUP_USER, UPDATE_REGISTERED_USERS, UPDATE_WATCHLIST, USER_MODAL } from "./actionTypes";

const initialState = {
  registeredUsers: [],
  currentUser: null,
  modal: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
      };

    case USER_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    
    case SIGNUP_USER:
      return {
        ...state,
        registeredUsers: action.payload,
      };

    case UPDATE_WATCHLIST:
      return {
        ...state,
        currentUser: {...state.currentUser, watchlist: [...state.currentUser.watchlist, action.payload]}
      };

    case UPDATE_REGISTERED_USERS:
      return {
        ...state,
        registeredUsers: action.payload
      };

    default:
      return state;
  }
};

export default userReducer;
