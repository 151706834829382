import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';

import {useUserActions} from '../store/user/userActions';

import HomeIcon from '../assets/icons/home-alt-regular.png';
import UserIcon from '../assets/icons/user-circle.png';
import Eclipse from '../assets/icons/dots-horizontal-rounded.png';
import Search from './Search';
import LoginModal from './LoginModal';

const Sidebar = () => {
    const [isDropdown, toggleDropDown] = useState(false);
    const [user, setUser] = useState();

    const userState = useSelector(state => state.user);

    useEffect(() => {
        setUser(userState.currentUser);
    }, [userState.currentUser]);

    const {userModal, userLogout, updateRegisteredUsers} = useUserActions();

    const handleRouteChange = (route) => {
        window.location.href = route;
    }

    const handleLogout = () => {
        const userIndex = userState.registeredUsers.findIndex(row => row.email == user.email);
        userState.registeredUsers[userIndex] = userState.currentUser;

        updateRegisteredUsers(userState.registeredUsers);
        userLogout();
    }
    return (
        <div className='sidebar'>

            {userState.modal && <LoginModal />}

            <h3 className='app-logo'>Watchlists</h3>

            <Search />

            <div className='menu-list'>
                <div onClick={() => handleRouteChange('/watchlists')} className={`menu-item active`}><img src={HomeIcon} /> <span>Home</span></div>
            </div>

            <div className='menu-watchlists'>
                <p className='section-subtitle'>My lists</p>
                {user?.watchlist && user.watchlist.length ? (
                    <>
                    <div className='menu-list'>
                        <span onClick={() => handleRouteChange('/mywatchlist')} className='menu-item cursor-pointer'>My Watchlist</span>
                    </div>
                    </>
                ) : ''}
            </div>

            <div className='menu-userdata'>
                {isDropdown && (<div className='drop-down'>
                    {!user && <span className='d-block w-100 cursor-pointer' onClick={() => {userModal(true); toggleDropDown(false)}}>Login</span>}
                    {user && <span className='d-block w-100 cursor-pointer' onClick={() => handleLogout()}>Logout</span>}
                </div>)}
                <span>
                    <img src={UserIcon} className='user-avatar' />
                    {user ? user.email : 'Guest'}
                </span>
                <img src={Eclipse} onClick={() => toggleDropDown(!isDropdown)} className='cursor-pointer'/>
            </div>
        </div>
    )
}

export default Sidebar;