import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MovieThumb from '../components/MovieThumb';
import { useMoviesActions } from '../store/movies/moviesActions';

const MyWatchlistPage = () => {
  const { currentUser } = useSelector(state => state.user);
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    if(currentUser && currentUser.watchlist) setMovies(currentUser.watchlist);
  }, []);

  return (
    <div>
      <div className='watchlist-banner border-0 p-0'>
        <h1>My Watchlist</h1>

        <h6>About this watchlist.</h6>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
      </div>

      <div className='movies-list'>
        {movies.length ? movies.map((movie, i) => <MovieThumb key={i} movie={movie} />) : (
          <h4>No Movies in watchlist</h4>
        )}
      </div>
    </div>
  );
};

export default MyWatchlistPage;
