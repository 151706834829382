import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {useUserActions} from '../store/user/userActions';

const LoginModal = () => {
    const [isLoginForm, toggleLoginForm] = useState(true);
    const [error, setError] = useState(null);
    const [userEmail, setUserEmail] = useState();

    useEffect(() => {
        setError(null);
    }, [isLoginForm]);

    const userState = useSelector(state => state.user);
    const {userModal, userLogin, signupUser} = useUserActions();

    const handleSignup = () => {
        const user = {email: userEmail, watchlist: []};
        signupUser([...userState.registeredUsers, user]);

        userLogin(user);
        userModal(false);
    }

    const handleLogin = () => {
        const user = userState.registeredUsers.find(row => row.email == userEmail);
        if(user){
            userLogin(user);
            userModal(false);
        }else{
            setError("Invalid Email. Please register");
        }
    }

    return (
        <div className="modal d-block" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <h4 className="w-100 text-center">{isLoginForm ? 'Login' : 'Register'}</h4>
                        <div className="form-group">
                            <label>Email</label>
                            <input value={userEmail} onInput={(e) => setUserEmail(e.target.value)} type="email" className="form-control" />
                        </div>

                        <div>
                            <span className="text-danger float-left">{error}</span>
                            <span className="ask-register" onClick={() => toggleLoginForm(!isLoginForm)}>{isLoginForm ? 'Register' : 'Login'}</span>
                        </div>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" onClick={isLoginForm ? handleLogin : handleSignup} className="btn btn-danger">{isLoginForm ? 'Login' : 'Register'}</button>
                        <button type="button" onClick={() => userModal(false)} className="btn btn-secondary">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginModal;