import logo from './logo.svg';
import './App.css';

import { AuthProvider } from './context/AuthContext'; // Ensure you have an AuthProvider
import AppRouter from './routes/AppRouter';
import Sidebar from './components/Sidebar';

// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/common.scss';

function App() {
  return (
    <>
      <AuthProvider>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-2 sidebar-wrapper'>
              <Sidebar />
            </div>
            <div className='col-sm-10 p-5 content-wrapper'>
              <AppRouter />
            </div>
          </div>
        </div>
      </AuthProvider>
    </>
  );
}

export default App;
