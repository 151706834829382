import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '../assets/icons/search-regular.png';
import { SEARCHED_VALUE } from '../store/movies/actionsTypes';
import {useMoviesActions} from '../store/movies/moviesActions';
import { useEffect, useState } from 'react';

const Search = ({searchButton = false}) => {
    const {searchValue} = useMoviesActions();
    const value = useSelector(state => state.movies.searchedValue);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        searchValue("");
    }, []);

    let timeout;
    const hadleInputChange = (e) => {
        timeout = setTimeout(() => {
            searchValue(e.target.value);
        }, 500);
    }
    return (
        <div className='searchbar-wrapper'>
            <img src={SearchIcon} />
            <input type="text" value={searchInput} onInput={(e) => setSearchInput(e.target.value)} onKeyUp={hadleInputChange} placeholder='Search' />
            {searchButton && <button className='btn btn-danger'>Search</button>}
        </div>
    )
}

export default Search;