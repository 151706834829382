import axios from "axios";

const API_KEY = "4174e3b0";
const API_BASE_URL = "http://www.omdbapi.com?apikey=#api#";

const sanitizedUrl = (endPoint) => {
  let url = API_BASE_URL.replace("#api#", API_KEY);
      url = url.concat(endPoint);

  return url;
}

export const httpGet = async (endPoint) => {
  try {
    const response = await axios.get(sanitizedUrl(endPoint));

    return response.data;
  } catch (e) {
    console.error("api error", e);
  }
};
