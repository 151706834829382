import { useDispatch, useSelector } from 'react-redux';
import { IS_LOADING, MOVIES_LIST, SEARCHED_VALUE, UPDATE_WATCHLIST } from './actionsTypes'; // Ensure correct file path

// Rename the function to use a hook naming convention
export const useMoviesActions = () => {
    const dispatch = useDispatch();

    // This function now correctly uses useDispatch within a custom hook
    return {
        searchValue: (value) => dispatch({ type: SEARCHED_VALUE, payload: value }),
        updateMoviesList: (value) => dispatch({ type: MOVIES_LIST, payload: value }),
        watchlist: (value) => dispatch({type: UPDATE_WATCHLIST, payload: value}),
        toggleLoading: (value) => dispatch({type: IS_LOADING, payload: value}),
    };
}
