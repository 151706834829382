import {
    MOVIES_LIST,
    SEARCHED_VALUE,
    IS_LOADING
} from "./actionsTypes";

const initialState = {
    moviesList: [],
    searchedValue: null,
    isLoading: false,
};

const moviesReducer = (state = initialState, action) => {
    switch (action.type) {
        case MOVIES_LIST:
            return {
                ...state,
                moviesList: action.payload,
            };
        case SEARCHED_VALUE:
            return {
                ...state,
                searchedValue: action.payload,
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};

export default moviesReducer;
