import { legacy_createStore as createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';  // Corrected import

import userReducer from './user/userReducer';
import moviesReducer from './movies/moviesReducer';

const persistConfig = {
  key: 'root',
  storage,
  debug: true
};

const rootReducer = combineReducers({
  user: userReducer,
  movies: moviesReducer,
});

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)
);

export const persistor = persistStore(store);
