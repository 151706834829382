import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import BookmarkIcon from '../assets/icons/bookmarks.png';

import MovieThumb from '../components/MovieThumb';
import Search from '../components/Search';
import {URLS} from '../api/routes';
import { httpGet } from '../api';
import { useMoviesActions } from '../store/movies/moviesActions';

const WatchlistPage = () => {
  const {searchedValue, moviesList, isLoading } = useSelector(state => state.movies);

  const {updateMoviesList, toggleLoading} = useMoviesActions();

  useEffect(() => {
    if(searchedValue){
        getMovies();
    } else {
      updateMoviesList([]);
    }
  }, [searchedValue]);

  const getMovies = async () => {
    try{
      toggleLoading(true);
      let url = URLS.search.replace("#text#", searchedValue);
      const response = await httpGet(url);
      toggleLoading(false);
      if(response.Error){
        alert(response.Error);
      } else if (response.Search) {
        updateMoviesList(response.Search.map(movie => {return {title: movie.Title, year: movie.Year, img: movie.Poster}}));
      }
    }catch(e){
      toggleLoading(false);
      console.log(e);
    }
  }

  return (
    <div>
      <div className='watchlist-banner'>
        <h1>Welcome to <span className='welcome-logo'>Watchlists</span></h1>

        <h6>Browse movies, add them to watchlists and share them with friends.</h6>
        <p>Just click the <span><img width={15} src={BookmarkIcon} /></span> to add a movie, the poster to see more details or mark the movies as watched.</p>
      </div>

      <Search searchButton={true}/>

      <div className='movies-list'>
        { isLoading && (<div className="spinner-border text-primary"></div>)}
        
        {(!isLoading && moviesList.length) ? moviesList.map((movie, i) => <MovieThumb key={i} movie={movie} />) : (
          <h4>{searchedValue ? 'No Movies' : 'You can search movies'}</h4>
        )}
      </div>
    </div>
  );
};

export default WatchlistPage;
