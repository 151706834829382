import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import WatchlistPage from '../pages/WatchlistPage';
import NotFoundPage from '../pages/NotFoundPage';
import MyWatchlistPage from '../pages/MyWatchlistPage';

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<WatchlistPage />} />
                <Route path="/watchlists" element={<WatchlistPage />} />
                <Route path="/mywatchlist" element={<MyWatchlistPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
