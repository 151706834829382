import { useSelector } from 'react-redux';
import BookmarkIcon from '../assets/icons/bookmarks.png';
import { useUserActions } from '../store/user/userActions';

const MovieThumb = ({ movie }) => {
    const {img = null, title = null, rating = null, year = null, is_saved = false } = movie;
    const {userWatchlist} = useUserActions();
    const userState = useSelector(state => state.user);

    const handleWatchList = (movie) => {
        userWatchlist(movie);
    }

    return (
        <>
            <div className="thumb-wrapper">
                <span className='bookmark-icon' onClick={() => handleWatchList(movie)}><img src={BookmarkIcon} /></span>
                <div className='movie-thumbnail' style={{
                    backgroundImage: `url(${img})`,
                }}></div>
                <div className="movie-meta">
                    {rating && (
                        <span className="movie-rating">{rating}
                            <sup>/100</sup>
                        </span>
                    )}
                    <h4>{title}</h4>
                    <p>({year})</p>
                </div>
            </div>
        </>
    )
}

export default MovieThumb;