import { useDispatch } from 'react-redux';
import { LOGIN_USER, LOGOUT_USER, USER_MODAL, SIGNUP_USER, UPDATE_WATCHLIST, UPDATE_REGISTERED_USERS } from './actionTypes';

export const useUserActions = () => {
    const dispatch = useDispatch();

    return {
        signupUser: (users) => dispatch({type: SIGNUP_USER, payload: users}),
        userLogin: (email) => dispatch({type: LOGIN_USER, payload: email}),
        userLogout: () => dispatch({type: LOGOUT_USER, payload: null}),
        userModal: (value) => dispatch({type: USER_MODAL, payload: value}),
        userWatchlist: (value) => dispatch({type: UPDATE_WATCHLIST, payload: value}),
        updateRegisteredUsers: (value) => dispatch({type: UPDATE_REGISTERED_USERS, payload: value}),
    }
}